<template>
  <div class="mx-1">
    <Buttons />
    <PaymentTable />
  </div>
</template>

<script>

import Buttons from './components/PaymentCalendar/Buttons.vue'
import PaymentTable from './components/PaymentCalendar/PaymentTable.vue'

export default {
  components: {
    Buttons,
    PaymentTable,
  },
}
</script>
