<template>
  <b-modal
    id="payment-create-modal"
    title="Add Payment Calendar"
    centered
    hide-footer
    no-close-on-backdrop
    @show="resetModal"
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Cycle Start Date"
              rules="required|before:@Cycle End Date"
            >
              <HeroInputDate
                id="cycle_start_date"
                v-model="cycle_start_date"
                label="Cycle Start Date"
                :required="true"
                column="4"
                :add-calendar="true"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Cycle End Date"
              rules="required|after:@Cycle Start Date"
            >
              <HeroInputDate
                id="cycle_end_date"
                v-model="cycle_end_date"
                label="Cycle End Date"
                :required="true"
                column="4"
                :add-calendar="true"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Placement Date"
              rules="required"
            >
              <HeroInputDate
                id="placement_date"
                v-model="placement_date"
                label="Placement Date"
                :required="true"
                column="4"
                :add-calendar="true"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Payment Date"
              rules="required"
            >
              <HeroInputDate
                id="payment_date"
                v-model="payment_date"
                label="Payment Date"
                :required="true"
                column="4"
                :add-calendar="true"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <HeroInputDate
              id="expected_payment_date"
              v-model="expected_payment_date"
              label="Expected Payment Date"
              column="4"
              :add-calendar="true"
              :readonly="true"
            />
          </b-col>
          <b-col cols="12">
            <HeroInputText
              id="accounting_remark"
              v-model="accounting_remark"
              label="Accounting Remark"
              column="4"
            />
          </b-col>
          <b-col cols="12">
            <HeroInputText
              id="collection_remark"
              v-model="collection_remark"
              label="Collection Remark"
              column="4"
            />
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="invalid"
              @click="createPaymentCalendar"
            >
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="outline-secondary"
              @click="$bvModal.hide('payment-create-modal')"
            >
              Back
            </HeroButtonAction>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import { extend } from 'vee-validate'

extend('after', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(target)
    const enddate = new Date(value)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'End date should be greater than and equal to start date',
})

extend('before', {
  params: ['target'],
  validate(value, { target }) {
    const startdate = new Date(value)
    const enddate = new Date(target)
    return startdate.getTime() <= enddate.getTime()
  },
  message: 'Start date should be less than and equal end date',
})

export default {
  components: {
    HeroInputText,
    HeroInputDate,
    HeroButtonAction,
  },
  data() {
    return {
      showOverlay: false,
      placement_date: '',
      payment_date: '',
      expected_payment_date: '',
      invoice_remark: '',
      accounting_remark: '',
      collection_remark: '',
      cycle_start_date: '',
      cycle_end_date: '',
    }
  },
  methods: {
    async createPaymentCalendar() {
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        try {
          const payload = {
            opportunity_id: this.$route.params.id,
            cycle_start_date: this.cycle_start_date,
            cycle_end_date: this.cycle_end_date,
            placement_date: this.placement_date,
            payment_date: this.payment_date,
            expected_payment_date: this.expected_payment_date,
            accounting_remark: this.accounting_remark,
            collection_remark: this.collection_remark,
          }
          const response = await AxiosService.post(Constant.apiURL.paymentCalendars, null, payload)

          if (response.status === true) {
            await this.$swal({ ...SweetAlert.success, html: response.message })
            this.$bvModal.hide('payment-create-modal')
            this.$store.state.heroAiCampaign.paymentCalendarState = !this.$store.state.heroAiCampaign.paymentCalendarState
            this.resetModal()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
          if (error.response.status === 404) {
            await this.$router.push({ name: 'page-not-found' })
          }
        }
      }
    },
    async resetModal() {
      this.placement_date = ''
      this.payment_date = ''
      this.expected_payment_date = ''
      this.invoice_remark = ''
      this.accounting_remark = ''
      this.collection_remark = ''
      this.cycle_start_date = ''
      this.cycle_end_date = ''
      await this.$refs.observer.reset()
    },
  },
}
</script>
