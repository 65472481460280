<template>
  <b-row class="mt-2">
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="opportunity_id"
        v-model="opportunity.opportunity_id"
        label="Opportunity ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="opportunity_name"
        v-model="opportunity.opportunity_name"
        label="Opportunity Name"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="customer_id"
        v-model="opportunity.customer_id"
        label="Customer ID"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="customer_name"
        v-model="opportunity.customer_name"
        label="Customer Name"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="payment_term"
        v-model="opportunity.payment_term"
        label="Payment Term"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="status"
        v-model="opportunity.status"
        label="Status"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText id="stage" v-model="opportunity.stage" label="Stage" />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="duration"
        :value="opportunity.duration.toString()"
        label="Duration"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText id="type" v-model="opportunity.type" label="Type" />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="slaesorder"
        v-model="opportunity.slaesorder"
        label="Sales Order"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="quote_no"
        v-model="opportunity.quote_no"
        label="Quote Number"
      />
    </b-col>
    <b-col cols="12" md="3">
      <HeroDisplayText
        id="invoice_required"
        v-model="opportunity.invoice_required"
        label="Invoice Required"
      />
    </b-col>
  </b-row>
</template>
<script>
import HeroDisplayText from '@/views/components/form/displays/HeroDisplayText.vue'
import PaymentCalendarHeader from '@/models/Opportunity/PaymentCalendarHeader'

export default {
  components: {
    HeroDisplayText,
  },
  props: {
    opportunity: {
      type: Object,
      required: true,
      default: () => new PaymentCalendarHeader(),
    },
  },
}
</script>
