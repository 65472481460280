<template>
  <div class="mx-1">
    <b-row>
      <b-modal
        id="modal-scrollable"
        scrollable
        title="Filter"
        cancel-title="Cancel"
        ok-title="Save"
        cancel-variant="outline-secondary"
        size="lg"
        @ok="doLoadData()"
      >
        <b-form @submit.stop.prevent="doLoadData()">
          <TextFieldFilter
            :title="filterModel.productName.title"
            :label1="filterModel.productName.label1"
            :field1title="filterModel.productName.field1title"
            :option="filterModel.productName.option"
            :label2="filterModel.productName.label2"
            :selected="vmodel.productName.operator"
            :input-text="vmodel.productName.value"
            @listchange="vmodel.productName.operator = $event"
            @input="vmodel.productName.value = $event"
          />
          <TextFieldFilter
            :title="filterModel.quantity.title"
            :label1="filterModel.quantity.label1"
            :field1title="filterModel.quantity.field1title"
            :option="filterModel.quantity.option"
            :label2="filterModel.quantity.label2"
            :label3="filterModel.quantity.label3"
            :label4="filterModel.quantity.label4"
            :selected="vmodel.quantity.operator"
            :input-text="vmodel.quantity.value"
            :input-text1="vmodel.quantity.value1"
            :input-text2="vmodel.quantity.value2"
            @listchange="vmodel.quantity.operator = $event"
            @input="vmodel.quantity.value = $event"
            @input1="vmodel.quantity.value1 = $event"
            @input2="vmodel.quantity.value2 = $event"
          />
          <TextFieldFilter
            :title="filterModel.sales_price.title"
            :label1="filterModel.sales_price.label1"
            :field1title="filterModel.sales_price.field1title"
            :option="filterModel.sales_price.option"
            :label2="filterModel.sales_price.label2"
            :label3="filterModel.quantity.label3"
            :label4="filterModel.quantity.label4"
            :selected="vmodel.sales_price.operator"
            :input-text="vmodel.sales_price.value"
            :input-text1="vmodel.sales_price.value1"
            :input-text2="vmodel.sales_price.value2"
            @listchange="vmodel.sales_price.operator = $event"
            @input="vmodel.sales_price.value = $event"
            @input1="vmodel.sales_price.value1 = $event"
            @input2="vmodel.sales_price.value2 = $event"
          />
        </b-form>
      </b-modal>
    </b-row>
    <b-row>
      <b-card-actions ref="opp_product_new" class="mb-2 w-100" no-body no-actions>
        <!-- !! Table Header -->
        <div class="mx-1">
          <b-row>
            <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
              <HeroInputText
                id="filter"
                v-model="tableConfig.filter"
                placeholder="Search"
                @input="doTableFilter(500)"
              />
            </b-col>
            <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
              <HeroTablePerPage
                v-model="tableConfig.perPage"
                :options="tableConfig.perPageOptions"
                @input="doTableFilter(100)"
              />
            </b-col>
            <b-col class="mt-1" order="2" cols="6" sm="2" md="3" lg="2">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-scrollable
                variant="outline-primary"
                class="ml-2 mb-2"
              >
                <feather-icon
                  icon="FilterIcon"
                  size="16"
                />
                Filter
              </b-button>
            </b-col>
          </b-row>
        </div>
        <!-- table -->
        <b-row>
          <b-col cols="12">
            <b-table
              class="mt-2"
              :no-border-collapse="false"
              show-empty
              striped
              sticky-header="100%"
              :responsive="true"
              :items="items"
              :fields="fields"
              :per-page="0"
              :sort-by="tableConfig.sortBy"
              :sort-direction="tableConfig.sortDirection"
              :no-sort-reset="true"
              :no-local-sorting="true"
              @sort-changed="doTableSort"
            >
              <template #cell(description)="data">
                <div class="span">
                  {{ data.item.description }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <!-- !! Table Footer -->
        <div class="mx-1 mb-1">
          <b-row>
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
              <HeroTablePagination
                v-model="tableConfig.currentPage"
                :per-page="tableConfig.perPage"
                :total-rows="tableConfig.totalRows"
                @input="doTableFilter(100)"
              />
            </b-col>
            <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
              <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
            </b-col>
          </b-row>
        </div>
      </b-card-actions>
    </b-row>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BRow,
  BModal,
  VBModal,
  BForm,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import TextFieldFilter from '@/views/heroai/opportunities/view/components/TextFieldFilter.vue'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'
import Constant from '@/utils/Constant'
import ProductFactory from '@/factories/Opportunity/Product'
import moment from 'moment'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'

export default {
  components: {
    BTable,
    BButton,
    BRow,
    BModal,
    BForm,
    BCol,
    BCardActions,
    TextFieldFilter,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'opportunity_id',
        sortDirection: 'asc',
        timeInterval: moment(),
      },
      filterModel: {
        productName: {
          title: 'Product Name',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        quantity: {
          title: 'Quantity',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
          label3: 'From',
          label4: 'To',
        },
        sales_price: {
          title: 'Sales Price',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
          label3: 'From',
          label4: 'To',
        },
      },
      vmodel: {
        productName: {
          operator: {},
          value: '',
        },
        quantity: {
          operator: {},
          value: '',
          value1: '',
          value2: '',
        },
        sales_price: {
          operator: {},
          value: '',
          value1: '',
          value2: '',
        },
      },
      selected: { title: 'Contain', value: 'contain' },
      selected2: { title: 'Equal', value: 'equal' },
      option: [
        { title: 'Contain', value: 'contain' },
        { title: 'Equal', value: 'equal' },
      ],
      option2: [
        { title: 'Equal', value: 'equal' },
        { title: 'Greater Than', value: 'greater_than' },
        { title: 'Less Than', value: 'less_than' },
        { title: 'Between', value: 'between' },
      ],
      fields: [
        { label: 'product', key: 'name', sortable: true },
        { label: 'quantity', key: 'quantity', sortable: true },
        { label: 'sales price', key: 'sales_price', sortable: true },
        { label: 'management fee', key: 'management_fee', sortable: true },
        { label: 'gross profit', key: 'gross_profit', sortable: true },
        { label: 'line description', key: 'description', sortable: true },
        { label: 'list price', key: 'price', sortable: true },
        { label: 'vendor name', key: 'vender_name', sortable: true },
        { label: 'vendor cost', key: 'vender_coast', sortable: true },
        { label: 'line item number', key: 'line_Item_Number__c', sortable: true },
      ],
      items: [
        {
          product_name: '',
          quantity: '',
          sales_price: '',
          management_fee: '',
          gross_profit: '',
          description: '',
          price: '',
          vender_name: '',
          vender_coast: '',
          line_Item_Number__c: '',
        },
      ],
      opportunityProductList: ProductFactory.createFromJsonArray([{}]),
    }
  },
  mounted() {
    this.vmodel.productName.operator = this.selected
    this.filterModel.productName.option = this.option
    this.vmodel.quantity.operator = this.selected2
    this.filterModel.quantity.option = this.option2
    this.vmodel.sales_price.operator = this.selected2
    this.filterModel.sales_price.option = this.option2
    this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.opp_product_new.showLoading = true

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const ProductName = {
          operator: this.vmodel.productName.operator && this.vmodel.productName.operator.value ? this.vmodel.productName.operator.value : '',
          value: this.vmodel.productName.value,
        }

        const Quantity = {
          operator: this.vmodel.quantity.operator && this.vmodel.quantity.operator.value ? this.vmodel.quantity.operator.value : '',
          value: this.vmodel.quantity.value,
          value1: this.vmodel.quantity.value1,
          value2: this.vmodel.quantity.value2,
        }

        const SalesPrice = {
          operator: this.vmodel.sales_price.operator && this.vmodel.sales_price.operator.value ? this.vmodel.sales_price.operator.value : '',
          value: this.vmodel.sales_price.value,
          value1: this.vmodel.sales_price.value1,
          value2: this.vmodel.sales_price.value2,
        }

        const qparam = {
          opportunity_id: this.$route.params.id,
          perPage,
          page: currentPage,
          q: filter,
          orderByColumn: sortBy,
          orderByDirection: sortDirection,
          product_name: ProductName,
          quantity: Quantity,
          sales_price: SalesPrice,
        }

        const response = await AxiosService.post(Constant.apiURL.salesforceOpportunityGetProducts, {}, qparam)
        if (response.status === true) {
          this.tableConfig.currentPage = Number(response.data.current_page)
          this.tableConfig.perPage = Number(response.data.per_page)
          this.tableConfig.totalRows = Number(response.data.total)
          this.items = ProductFactory.createFromJsonArray(response.data.data)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        this.$refs.opp_product_new.showLoading = false
      }
    },

    columnDate(data) {
      if (data) {
        return moment(data).tz('Asia/Bangkok').format('MMMM DD, YYYY')
      }

      return null
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
}
</style>
