<template>
  <div>
    <!-- <b-row class="mb-2">
      <b-col cols="2" />
      <b-col v-if="opportunityType === 'New Business' || opportunityType === 'Existing Business'" cols="2" offset="8" />
    </b-row> -->
    <b-modal
      id="modal-campaign-filter"
      scrollable
      title="Filter"
      cancel-title="Cancel"
      ok-title="Save"
      cancel-variant="outline-secondary"
      size="lg"
    >
      <b-form @submit.stop.prevent>
        <TextFieldFilter
          :title="filterModel.name.title"
          :label1="filterModel.name.label1"
          :field1title="filterModel.name.field1title"
          :option="filterModel.name.option"
          :label2="filterModel.name.label2"
          :selected="vmodel.name.selected"
          :input-text="vmodel.name.inputText"
          @listchange="vmodel.name.selected = $event"
          @input="vmodel.name.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.period.title"
          :label1="filterModel.period.label1"
          :field1title="filterModel.period.field1title"
          :option="filterModel.period.option"
          :label2="filterModel.period.label2"
          :selected="vmodel.period.selected"
          :input-text="vmodel.period.inputText"
          @listchange="vmodel.period.selected = $event"
          @input="vmodel.period.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.status.title"
          :label1="filterModel.status.label1"
          :field1title="filterModel.status.field1title"
          :option="filterModel.status.option"
          :label2="filterModel.status.label2"
          :selected="vmodel.status.selected"
          :input-text="vmodel.status.inputText"
          @listchange="vmodel.status.selected = $event"
          @input="vmodel.status.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.opportunityName.title"
          :label1="filterModel.opportunityName.label1"
          :field1title="filterModel.opportunityName.field1title"
          :option="filterModel.opportunityName.option"
          :label2="filterModel.opportunityName.label2"
          :selected="vmodel.opportunityName.selected"
          :input-text="vmodel.opportunityName.inputText"
          @listchange="vmodel.opportunityName.selected = $event"
          @input="vmodel.opportunityName.inputText = $event"
        />
        <DateFilter
          :title="filterModel.briefDate.title"
          :start-date="vmodel.briefDate.startDate"
          :end-date="vmodel.briefDate.endDate"
          @startDtChange="vmodel.briefDate.startDate = $event"
          @endDtChange="vmodel.briefDate.endDate = $event"
        />
        <DateFilter
          :title="filterModel.startDate.title"
          :start-date="vmodel.startDate.startDate"
          :end-date="vmodel.startDate.endDate"
          @startDtChange="vmodel.startDate.startDate = $event"
          @endDtChange="vmodel.startDate.endDate = $event"
        />
        <DateFilter
          :title="filterModel.endDate.title"
          :start-date="vmodel.endDate.startDate"
          :end-date="vmodel.endDate.endDate"
          @startDtChange="vmodel.endDate.startDate = $event"
          @endDtChange="vmodel.endDate.endDate = $event"
        />
        <DateFilter
          :title="filterModel.pauseDate.title"
          :start-date="vmodel.pauseDate.startDate"
          :end-date="vmodel.pauseDate.endDate"
          @startDtChange="vmodel.pauseDate.startDate = $event"
          @endDtChange="vmodel.pauseDate.endDate = $event"
        />
        <DateFilter
          :title="filterModel.restartDate.title"
          :start-date="vmodel.restartDate.startDate"
          :end-date="vmodel.restartDate.endDate"
          @startDtChange="vmodel.restartDate.startDate = $event"
          @endDtChange="vmodel.restartDate.endDate = $event"
        />
        <TextFieldFilter
          :title="filterModel.kpi.title"
          :label1="filterModel.kpi.label1"
          :field1title="filterModel.kpi.field1title"
          :option="filterModel.kpi.option"
          :label2="filterModel.kpi.label2"
          :selected="vmodel.kpi.selected"
          :input-text="vmodel.kpi.inputText"
          @listchange="vmodel.kpi.selected = $event"
          @input="vmodel.kpi.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.perCycleBudget.title"
          :label1="filterModel.perCycleBudget.label1"
          :field1title="filterModel.perCycleBudget.field1title"
          :option="filterModel.perCycleBudget.option"
          :label2="filterModel.perCycleBudget.label2"
          :selected="vmodel.perCycleBudget.selected"
          :input-text="vmodel.perCycleBudget.inputText"
          @listchange="vmodel.perCycleBudget.selected = $event"
          @input="vmodel.perCycleBudget.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.heroAICampaignId.title"
          :label1="filterModel.heroAICampaignId.label1"
          :field1title="filterModel.heroAICampaignId.field1title"
          :option="filterModel.heroAICampaignId.option"
          :label2="filterModel.heroAICampaignId.label2"
          :selected="vmodel.heroAICampaignId.selected"
          :input-text="vmodel.heroAICampaignId.inputText"
          @listchange="vmodel.heroAICampaignId.selected = $event"
          @input="vmodel.heroAICampaignId.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.googleAccountID.title"
          :label1="filterModel.googleAccountID.label1"
          :field1title="filterModel.googleAccountID.field1title"
          :option="filterModel.googleAccountID.option"
          :label2="filterModel.googleAccountID.label2"
          :selected="vmodel.googleAccountID.selected"
          :input-text="vmodel.googleAccountID.inputText"
          @listchange="vmodel.googleAccountID.selected = $event"
          @input="vmodel.googleAccountID.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.facebookAccountID.title"
          :label1="filterModel.facebookAccountID.label1"
          :field1title="filterModel.facebookAccountID.field1title"
          :option="filterModel.facebookAccountID.option"
          :label2="filterModel.facebookAccountID.label2"
          :selected="vmodel.facebookAccountID.selected"
          :input-text="vmodel.facebookAccountID.inputText"
          @listchange="vmodel.facebookAccountID.selected = $event"
          @input="vmodel.facebookAccountID.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.pricingModel.title"
          :label1="filterModel.pricingModel.label1"
          :field1title="filterModel.pricingModel.field1title"
          :option="filterModel.pricingModel.option"
          :label2="filterModel.pricingModel.label2"
          :selected="vmodel.pricingModel.selected"
          :input-text="vmodel.pricingModel.inputText"
          @listchange="vmodel.pricingModel.selected = $event"
          @input="vmodel.pricingModel.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.bonus.title"
          :label1="filterModel.bonus.label1"
          :field1title="filterModel.bonus.field1title"
          :option="filterModel.bonus.option"
          :label2="filterModel.bonus.label2"
          :selected="vmodel.bonus.selected"
          :input-text="vmodel.bonus.inputText"
          @listchange="vmodel.bonus.selected = $event"
          @input="vmodel.bonus.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.landingPageType.title"
          :label1="filterModel.landingPageType.label1"
          :field1title="filterModel.landingPageType.field1title"
          :option="filterModel.landingPageType.option"
          :label2="filterModel.landingPageType.label2"
          :selected="vmodel.landingPageType.selected"
          :input-text="vmodel.landingPageType.inputText"
          @listchange="vmodel.landingPageType.selected = $event"
          @input="vmodel.landingPageType.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.uniquedSellingPoint.title"
          :label1="filterModel.uniquedSellingPoint.label1"
          :field1title="filterModel.uniquedSellingPoint.field1title"
          :option="filterModel.uniquedSellingPoint.option"
          :label2="filterModel.uniquedSellingPoint.label2"
          :selected="vmodel.uniquedSellingPoint.selected"
          :input-text="vmodel.uniquedSellingPoint.inputText"
          @listchange="vmodel.uniquedSellingPoint.selected = $event"
          @input="vmodel.uniquedSellingPoint.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.servicesFocus.title"
          :label1="filterModel.servicesFocus.label1"
          :field1title="filterModel.servicesFocus.field1title"
          :option="filterModel.servicesFocus.option"
          :label2="filterModel.servicesFocus.label2"
          :selected="vmodel.servicesFocus.selected"
          :input-text="vmodel.servicesFocus.inputText"
          @listchange="vmodel.servicesFocus.selected = $event"
          @input="vmodel.servicesFocus.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.keywordsIdeas.title"
          :label1="filterModel.keywordsIdeas.label1"
          :field1title="filterModel.keywordsIdeas.field1title"
          :option="filterModel.keywordsIdeas.option"
          :label2="filterModel.keywordsIdeas.label2"
          :selected="vmodel.keywordsIdeas.selected"
          :input-text="vmodel.keywordsIdeas.inputText"
          @listchange="vmodel.keywordsIdeas.selected = $event"
          @input="vmodel.keywordsIdeas.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.DIDPhone.title"
          :label1="filterModel.DIDPhone.label1"
          :field1title="filterModel.DIDPhone.field1title"
          :option="filterModel.DIDPhone.option"
          :label2="filterModel.DIDPhone.label2"
          :selected="vmodel.DIDPhone.selected"
          :input-text="vmodel.DIDPhone.inputText"
          @listchange="vmodel.DIDPhone.selected = $event"
          @input="vmodel.DIDPhone.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.remark.title"
          :label1="filterModel.remark.label1"
          :field1title="filterModel.remark.field1title"
          :option="filterModel.remark.option"
          :label2="filterModel.remark.label2"
          :selected="vmodel.remark.selected"
          :input-text="vmodel.remark.inputText"
          @listchange="vmodel.remark.selected = $event"
          @input="vmodel.remark.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.industry.title"
          :label1="filterModel.industry.label1"
          :field1title="filterModel.industry.field1title"
          :option="filterModel.industry.option"
          :label2="filterModel.industry.label2"
          :selected="vmodel.industry.selected"
          :input-text="vmodel.industry.inputText"
          @listchange="vmodel.industry.selected = $event"
          @input="vmodel.industry.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.mediaPlan.title"
          :label1="filterModel.mediaPlan.label1"
          :field1title="filterModel.mediaPlan.field1title"
          :option="filterModel.mediaPlan.option"
          :label2="filterModel.mediaPlan.label2"
          :selected="vmodel.mediaPlan.selected"
          :input-text="vmodel.mediaPlan.inputText"
          @listchange="vmodel.mediaPlan.selected = $event"
          @input="vmodel.mediaPlan.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.contractBudget.title"
          :label1="filterModel.contractBudget.label1"
          :field1title="filterModel.contractBudget.field1title"
          :option="filterModel.contractBudget.option"
          :label2="filterModel.contractBudget.label2"
          :selected="vmodel.contractBudget.selected"
          :input-text="vmodel.contractBudget.inputText"
          @listchange="vmodel.contractBudget.selected = $event"
          @input="vmodel.contractBudget.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.objective.title"
          :label1="filterModel.objective.label1"
          :field1title="filterModel.objective.field1title"
          :option="filterModel.objective.option"
          :label2="filterModel.objective.label2"
          :selected="vmodel.objective.selected"
          :input-text="vmodel.objective.inputText"
          @listchange="vmodel.objective.selected = $event"
          @input="vmodel.objective.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.country.title"
          :label1="filterModel.country.label1"
          :field1title="filterModel.country.field1title"
          :option="filterModel.country.option"
          :label2="filterModel.country.label2"
          :selected="vmodel.country.selected"
          :input-text="vmodel.country.inputText"
          @listchange="vmodel.country.selected = $event"
          @input="vmodel.country.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.lineNotifications.title"
          :label1="filterModel.lineNotifications.label1"
          :field1title="filterModel.lineNotifications.field1title"
          :option="filterModel.lineNotifications.option"
          :label2="filterModel.lineNotifications.label2"
          :selected="vmodel.lineNotifications.selected"
          :input-text="vmodel.lineNotifications.inputText"
          @listchange="vmodel.lineNotifications.selected = $event"
          @input="vmodel.lineNotifications.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.lineNotificationLanguage.title"
          :label1="filterModel.lineNotificationLanguage.label1"
          :field1title="filterModel.lineNotificationLanguage.field1title"
          :option="filterModel.lineNotificationLanguage.option"
          :label2="filterModel.lineNotificationLanguage.label2"
          :selected="vmodel.lineNotificationLanguage.selected"
          :input-text="vmodel.lineNotificationLanguage.inputText"
          @listchange="vmodel.lineNotificationLanguage.selected = $event"
          @input="vmodel.lineNotificationLanguage.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.artworkPerMonth.title"
          :label1="filterModel.artworkPerMonth.label1"
          :field1title="filterModel.artworkPerMonth.field1title"
          :option="filterModel.artworkPerMonth.option"
          :label2="filterModel.artworkPerMonth.label2"
          :selected="vmodel.artworkPerMonth.selected"
          :input-text="vmodel.artworkPerMonth.inputText"
          @listchange="vmodel.artworkPerMonth.selected = $event"
          @input="vmodel.artworkPerMonth.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.howManyPieces.title"
          :label1="filterModel.howManyPieces.label1"
          :field1title="filterModel.howManyPieces.field1title"
          :option="filterModel.howManyPieces.option"
          :label2="filterModel.howManyPieces.label2"
          :selected="vmodel.howManyPieces.selected"
          :input-text="vmodel.howManyPieces.inputText"
          @listchange="vmodel.howManyPieces.selected = $event"
          @input="vmodel.howManyPieces.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.ANAWorkType.title"
          :label1="filterModel.ANAWorkType.label1"
          :field1title="filterModel.ANAWorkType.field1title"
          :option="filterModel.ANAWorkType.option"
          :label2="filterModel.ANAWorkType.label2"
          :selected="vmodel.ANAWorkType.selected"
          :input-text="vmodel.ANAWorkType.inputText"
          @listchange="vmodel.ANAWorkType.selected = $event"
          @input="vmodel.ANAWorkType.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.googleDriveLink.title"
          :label1="filterModel.googleDriveLink.label1"
          :field1title="filterModel.googleDriveLink.field1title"
          :option="filterModel.googleDriveLink.option"
          :label2="filterModel.googleDriveLink.label2"
          :selected="vmodel.googleDriveLink.selected"
          :input-text="vmodel.googleDriveLink.inputText"
          @listchange="vmodel.googleDriveLink.selected = $event"
          @input="vmodel.googleDriveLink.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.additionalRequirements.title"
          :label1="filterModel.additionalRequirements.label1"
          :field1title="filterModel.additionalRequirements.field1title"
          :option="filterModel.additionalRequirements.option"
          :label2="filterModel.additionalRequirements.label2"
          :selected="vmodel.additionalRequirements.selected"
          :input-text="vmodel.additionalRequirements.inputText"
          @listchange="vmodel.additionalRequirements.selected = $event"
          @input="vmodel.additionalRequirements.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.globalPage.title"
          :label1="filterModel.globalPage.label1"
          :field1title="filterModel.globalPage.field1title"
          :option="filterModel.globalPage.option"
          :label2="filterModel.globalPage.label2"
          :selected="vmodel.globalPage.selected"
          :input-text="vmodel.globalPage.inputText"
          @listchange="vmodel.globalPage.selected = $event"
          @input="vmodel.globalPage.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.existingBot.title"
          :label1="filterModel.existingBot.label1"
          :field1title="filterModel.existingBot.field1title"
          :option="filterModel.existingBot.option"
          :label2="filterModel.existingBot.label2"
          :selected="vmodel.existingBot.selected"
          :input-text="vmodel.existingBot.inputText"
          @listchange="vmodel.existingBot.selected = $event"
          @input="vmodel.existingBot.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.contentFromAM.title"
          :label1="filterModel.contentFromAM.label1"
          :field1title="filterModel.contentFromAM.field1title"
          :option="filterModel.contentFromAM.option"
          :label2="filterModel.contentFromAM.label2"
          :selected="vmodel.contentFromAM.selected"
          :input-text="vmodel.contentFromAM.inputText"
          @listchange="vmodel.contentFromAM.selected = $event"
          @input="vmodel.contentFromAM.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.requiredFields.title"
          :label1="filterModel.requiredFields.label1"
          :field1title="filterModel.requiredFields.field1title"
          :option="filterModel.requiredFields.option"
          :label2="filterModel.requiredFields.label2"
          :selected="vmodel.requiredFields.selected"
          :input-text="vmodel.requiredFields.inputText"
          @listchange="vmodel.requiredFields.selected = $event"
          @input="vmodel.requiredFields.inputText = $event"
        />
        <DateFilter
          :title="filterModel.chatbotLaunchDate.title"
          :start-date="vmodel.chatbotLaunchDate.startDate"
          :end-date="vmodel.chatbotLaunchDate.endDate"
          @startDtChange="vmodel.chatbotLaunchDate.startDate = $event"
          @endDtChange="vmodel.chatbotLaunchDate.endDate = $event"
        />
        <TextFieldFilter
          :title="filterModel.flowType.title"
          :label1="filterModel.flowType.label1"
          :field1title="filterModel.flowType.field1title"
          :option="filterModel.flowType.option"
          :label2="filterModel.flowType.label2"
          :selected="vmodel.flowType.selected"
          :input-text="vmodel.flowType.inputText"
          @listchange="vmodel.flowType.selected = $event"
          @input="vmodel.flowType.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.leadDataRequirements.title"
          :label1="filterModel.leadDataRequirements.label1"
          :field1title="filterModel.leadDataRequirements.field1title"
          :option="filterModel.leadDataRequirements.option"
          :label2="filterModel.leadDataRequirements.label2"
          :selected="vmodel.leadDataRequirements.selected"
          :input-text="vmodel.leadDataRequirements.inputText"
          @listchange="vmodel.leadDataRequirements.selected = $event"
          @input="vmodel.leadDataRequirements.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.productServiceDoc.title"
          :label1="filterModel.productServiceDoc.label1"
          :field1title="filterModel.productServiceDoc.field1title"
          :option="filterModel.productServiceDoc.option"
          :label2="filterModel.productServiceDoc.label2"
          :selected="vmodel.productServiceDoc.selected"
          :input-text="vmodel.productServiceDoc.inputText"
          @listchange="vmodel.productServiceDoc.selected = $event"
          @input="vmodel.productServiceDoc.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.anyFAQ.title"
          :label1="filterModel.anyFAQ.label1"
          :field1title="filterModel.anyFAQ.field1title"
          :option="filterModel.anyFAQ.option"
          :label2="filterModel.anyFAQ.label2"
          :selected="vmodel.anyFAQ.selected"
          :input-text="vmodel.anyFAQ.inputText"
          @listchange="vmodel.anyFAQ.selected = $event"
          @input="vmodel.anyFAQ.inputText = $event"
        />
        <TextFieldFilter
          :title="filterModel.anypromotion.title"
          :label1="filterModel.anypromotion.label1"
          :field1title="filterModel.anypromotion.field1title"
          :option="filterModel.anypromotion.option"
          :label2="filterModel.anypromotion.label2"
          :selected="vmodel.anypromotion.selected"
          :input-text="vmodel.anypromotion.inputText"
          @listchange="vmodel.anypromotion.selected = $event"
          @input="vmodel.anypromotion.inputText = $event"
        />
      </b-form>
    </b-modal>

    <b-card-actions ref="campaigns" class="mb-2" no-body no-actions>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" order="1" cols="12" lg="6" md="3" sm="6">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
          <b-col class="mt-1" order="2" cols="12" lg="2" md="3" sm="6">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col class="mt-1 text-right" order="3" cols="12" lg="2" md="3" sm="6">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-campaign-filter
              variant="outline-primary"
            >
              <feather-icon
                icon="FilterIcon"
                size="16"
              />
              Filter
            </b-button>
          </b-col>
          <b-col
            v-if="$can('Create Campaign', 'Opportunities > Campaign') && (opportunityType === 'New Business' || opportunityType === 'Existing Business')"
            class="mt-1"
            order="4"
            cols="12"
            lg="2"
            md="3"
            sm="6"
          >
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="opportunityRemaining < 1"
              btnclass="new-campaign"
              @click="setOpportunityId"
            >
              Create New Campaign
            </HeroButtonAction>
          </b-col>
        </b-row>
      </div>
      <!-- table -->
      <b-table
        :no-border-collapse="false"
        show-empty
        striped
        sticky-header="100%"
        :responsive="true"
        :items="items"
        :fields="fields"
        :per-page="0"
        :sort-by="tableConfig.sortBy"
        :sort-direction="tableConfig.sortDirection"
        :no-sort-reset="true"
        :no-local-sorting="true"
        @sort-changed="doTableSort"
      >
        <template #cell(name)="data">
          <div class="span span:hover" :style="`width: ${data.field.thwidth}px;`">
            <span v-if="data.item.demo_campaign" class="text-warning">●</span>
            <span v-if="!data.item.demo_campaign" class="text-success">●</span>
            <router-link :to="{name: 'campaign-detail', params: {id: data.item.campaign_id}}">
              {{ data.item.name }}
            </router-link>
          </div>
        </template>
        <template #cell(uniqued_selling_point)="data">
          <div class="span span:hover">
            {{ data.item.uniqued_selling_point }}
          </div>
        </template>
        <template #cell(actual_campaign_start_date)="data">
          {{ formatUsaDate(data.item.actual_campaign_start_date) }}
        </template>
        <template #cell(actual_campaign_end_date)="data">
          {{ formatUsaDate(data.item.actual_campaign_end_date) }}
        </template>
        <template #cell(brief_date)="data">
          {{ formatUsaDate(data.item.brief_date) }}
        </template>
        <template #cell(paused_date)="data">
          {{ formatUsaDate(data.item.paused_date) }}
        </template>
        <template #cell(unpaused_date)="data">
          {{ formatUsaDate(data.item.unpaused_date) }}
        </template>
        <template #cell(viewers)="data">
          <div class="span span:hover">
            {{ data.item.viewers.join(', ') }}
          </div>
        </template>
        <template #cell(line_notifications)="data">
          <div class="span span:hover">
            {{ data.item.line_notifications.map(x => x.email).join(', ') }}
          </div>
        </template>
        <template #cell(google_account_id)="data">
          <div class="span span:hover">
            {{ data.item.google_account_id.join(', ') }}
          </div>
        </template>
        <template #cell(facebook_account_id)="data">
          <div class="span span:hover">
            {{ data.item.facebook_account_id.join(', ') }}
          </div>
        </template>
        <template #cell(tiktok_account_id)="data">
          <div class="span span:hover">
            {{ data.item.tiktok_account_id.join(', ') }}
          </div>
        </template>
        <template #cell(did_phone)="data">
          <div class="span span:hover">
            {{ data.item.did_phone.join(', ') }}
          </div>
        </template>
      </b-table>
      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
    </b-card-actions>
  </div>
</template>

<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import {
  BRow,
  BCol,
  BButton,
  BForm,
  BModal,
  BTable,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import TextFieldFilter from '@/views/heroai/opportunities/view/components/TextFieldFilter.vue'
import DateFilter from '@/views/heroai/opportunities/view/components/DateFilter.vue'
import OpportunityCampaignFactory from '@/factories/Opportunity/OpportunityCampaign'
import SweetAlert from '@/services/SweetAlert'
import AxiosService from '@/services/AxiosService'
import ErrorService from '@/services/ErrorService'
import Constant from '@/utils/Constant'
import moment from 'moment'
import DataFormatService from '@/services/DataFormatService'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BForm,
    BModal,
    BTable,
    BCardActions,
    TextFieldFilter,
    DateFilter,
    HeroButtonAction,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    opportunityType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'opportunity_id',
        sortDirection: 'asc',
        timeInterval: moment(),
      },
      filterModel: {
        name: {
          title: 'Campaign Name',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        period: {
          title: 'Duration',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        status: {
          title: 'Status',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        opportunityName: {
          title: 'Opportunity Name',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        briefDate: {
          title: 'Brief Date',
        },
        startDate: {
          title: 'Start Date',
        },
        endDate: {
          title: 'End Date',
        },
        pauseDate: {
          title: 'Campaign Pause Date',
        },
        restartDate: {
          title: 'Campaign Restart Date',
        },
        kpi: {
          title: 'KPI',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        perCycleBudget: {
          title: 'Per Cycle Budget',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        heroAICampaignId: {
          title: 'HeroAI Campaign ID',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        googleAccountID: {
          title: 'Google Account ID',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        facebookAccountID: {
          title: 'Facebook Account ID',
          label1: 'Operator',
          field1title: 'title',
          option: [],
          label2: 'Value',
        },
        pricingModel: {
          title: 'Pricing Model',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        bonus: {
          title: 'Bonus/Discount',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        landingPageType: {
          title: 'Landing Page Type',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        uniquedSellingPoint: {
          title: 'Uniqued Selling Point',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        servicesFocus: {
          title: 'Services Focus',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        keywordsIdeas: {
          title: 'Keywords Ideas',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        DIDPhone: {
          title: 'DID Phone',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        remark: {
          title: 'Remark',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        industry: {
          title: 'Industry',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        mediaPlan: {
          title: 'Media Plan',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        contractBudget: {
          title: 'Contract Budget',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        objective: {
          title: 'Objective',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        country: {
          title: 'Country',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        lineNotifications: {
          title: 'Line Notifications',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        lineNotificationLanguage: {
          title: 'Line Notification Language',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        artworkPerMonth: {
          title: 'Viewers Do you commit amount of Artwork per month?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        howManyPieces: {
          title: 'How many pieces?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        ANAWorkType: {
          title: '[ANA] Work Type',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        googleDriveLink: {
          title: 'Google Drive Link under client folder',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        additionalRequirements: {
          title: 'Additional requirements, operation must request Sale at brief meeting Facebook page access, please fill page name',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        globalPage: {
          title: 'Is this a global/shared page for the property or a dedicated facebook page for just our LP/Campaign/Client brand?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        existingBot: {
          title: 'If there is any existing bot and/or Auto-message on the page, can we remove?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        contentFromAM: {
          title: 'Content from AM/Client/Copywriter/ for question',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        requiredFields: {
          title: 'Required fields from chat lead (Email, surname, etc.)',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        chatbotLaunchDate: {
          title: 'Chatbot Launch date',
        },
        flowType: {
          title: 'Please select Flow type',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        leadDataRequirements: {
          title: 'Lead data Requirements(email, phone)?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        productServiceDoc: {
          title: 'Product, service, branch information document',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        anyFAQ: {
          title: 'Any FAQ questions to add to the flow?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
        anypromotion: {
          title: 'Any promotion offer to show the user?',
          label1: 'Operator',
          field1title: 'title',
          options: [],
          label2: 'Value',
        },
      },
      vmodel: {
        name: {
          selected: {},
          inputText: '',
        },
        period: {
          selected: {},
          inputText: '',
        },
        status: {
          selected: {},
          inputText: '',
        },
        opportunityName: {
          selected: {},
          inputText: '',
        },
        pricingModel: {
          selected: {},
          inputText: '',
        },
        briefDate: {
          startDate: '',
          endDate: '',
        },
        startDate: {
          startDate: '',
          endDate: '',
        },
        endDate: {
          startDate: '',
          endDate: '',
        },
        pauseDate: {
          startDate: '',
          endDate: '',
        },
        restartDate: {
          startDate: '',
          endDate: '',
        },
        kpi: {
          selected: {},
          inputText: '',
        },
        perCycleBudget: {
          selected: {},
          inputText: '',
        },
        heroAICampaignId: {
          selected: {},
          inputText: '',
        },
        googleAccountID: {
          selected: {},
          inputText: '',
        },
        facebookAccountID: {
          selected: {},
          inputText: '',
        },
        bonus: {
          selected: {},
          inputText: '',
        },
        landingPageType: {
          selected: {},
          inputText: '',
        },
        uniquedSellingPoint: {
          selected: {},
          inputText: '',
        },
        servicesFocus: {
          selected: {},
          inputText: '',
        },
        keywordsIdeas: {
          selected: {},
          inputText: '',
        },
        DIDPhone: {
          selected: {},
          inputText: '',
        },
        remark: {
          selected: {},
          inputText: '',
        },
        industry: {
          selected: {},
          inputText: '',
        },
        mediaPlan: {
          selected: {},
          inputText: '',
        },
        contractBudget: {
          selected: {},
          inputText: '',
        },
        objective: {
          selected: {},
          inputText: '',
        },
        country: {
          selected: {},
          inputText: '',
        },
        lineNotifications: {
          selected: {},
          inputText: '',
        },
        lineNotificationLanguage: {
          selected: {},
          inputText: '',
        },
        artworkPerMonth: {
          selected: {},
          inputText: '',
        },
        howManyPieces: {
          selected: {},
          inputText: '',
        },
        ANAWorkType: {
          selected: {},
          inputText: '',
        },
        googleDriveLink: {
          selected: {},
          inputText: '',
        },
        additionalRequirements: {
          selected: {},
          inputText: '',
        },
        globalPage: {
          selected: {},
          inputText: '',
        },
        existingBot: {
          selected: {},
          inputText: '',
        },
        contentFromAM: {
          selected: {},
          inputText: '',
        },
        requiredFields: {
          selected: {},
          inputText: '',
        },
        chatbotLaunchDate: {
          startDate: '',
          endDate: '',
        },
        flowType: {
          selected: {},
          inputText: '',
        },
        leadDataRequirements: {
          selected: {},
          inputText: '',
        },
        productServiceDoc: {
          selected: {},
          inputText: '',
        },
        anyFAQ: {
          selected: {},
          inputText: '',
        },
        anypromotion: {
          selected: {},
          inputText: '',
        },
      },
      selected: { title: 'Contain' },
      selected2: { title: 'Greater Than' },
      option: [{ title: 'Contain' }, { title: 'Equal' }],
      option2: [{ title: 'Greater Than' }, { title: 'Equal' }, { title: 'Less Than' }, { title: 'Between' }],
      fields: [
        {
          label: 'Campaign name', key: 'name', stickyColumn: true, thwidth: '200',
        },
        { label: 'Duration', key: 'period' },
        { label: 'status', key: 'campaign_status' },
        { label: 'opportunity name', key: 'opportunity' },
        { label: 'Pricing model', key: 'pricing_model' },
        { label: 'brief date', key: 'brief_date' },
        { label: 'start date', key: 'actual_campaign_start_date' },
        { label: 'end date', key: 'actual_campaign_end_date' },
        { label: 'campaign pause date', key: 'paused_date' },
        { label: 'campaign restart date', key: 'unpaused_date' },
        { label: 'day left', key: 'day_left' },
        { label: 'kpi', key: 'kpi' },
        { label: 'per cycle budget', key: 'per_cycle_budget' },
        { label: 'heroAI campaign ID', key: 'heroai_campaign_id' },
        { label: 'google account ID', key: 'google_account_id' },
        { label: 'facebook account ID', key: 'facebook_account_id' },
        { label: 'tiktok account ID', key: 'tiktok_account_id' },
        { label: 'bonus/discount', key: 'bonus_discount' },
        { label: 'landing page type', key: 'landing_page_type' },
        { label: 'landing page languages', key: 'landing_page_language' },
        { label: 'uniqued selling point', key: 'uniqued_selling_point' },
        { label: 'services focus', key: 'service_focus' },
        { label: 'keywords ideas', key: 'keyword_ideas' },
        { label: 'DID phone', key: 'did_phone' },
        { label: 'creator', key: 'creator' },
        { label: 'remark', key: 'remark' },
        { label: 'industry', key: 'industry' },
        { label: 'media plan', key: 'media_plan' },
        { label: 'objective', key: 'objectives' },
        { label: 'country', key: 'country_id' },
        { label: 'line notifications', key: 'line_notifications' },
        { label: 'line notification language', key: 'line_notifications_language' },
        { label: 'viewers', key: 'viewers' },
      ],
      items: [],
      opportunityCampaignList: OpportunityCampaignFactory.createFromJsonArray([{}]),
      opportunityRemaining: 0,
    }
  },
  computed: {
    hasMyCampaignAccess() {
      return this.$can('Access - My Campaign', 'Campaigns')
    },

    hasAllCampaignAccess() {
      return this.$can('Access - All Campaign', 'Campaigns')
    },
  },
  created() {
    this.formatUsaDate = DataFormatService.formatUsaDate
  },
  mounted() {
    this.vmodel.name.selected = this.selected
    this.filterModel.name.option = this.option
    this.vmodel.period.selected = this.selected
    this.filterModel.period.option = this.option
    this.vmodel.status.selected = this.selected
    this.filterModel.status.option = this.option
    this.vmodel.opportunityName.selected = this.selected
    this.filterModel.opportunityName.option = this.option
    this.vmodel.briefDate.startDate = this.startDate
    this.vmodel.briefDate.endDate = this.endDate
    this.vmodel.startDate.startDate = this.startDate
    this.vmodel.startDate.endDate = this.endDate
    this.vmodel.endDate.startDate = this.startDate
    this.vmodel.endDate.endDate = this.endDate
    this.vmodel.pauseDate.startDate = this.startDate
    this.vmodel.pauseDate.endDate = this.endDate
    this.vmodel.restartDate.startDate = this.startDate
    this.vmodel.restartDate.endDate = this.endDate
    this.vmodel.kpi.selected = this.selected
    this.filterModel.kpi.option = this.option
    this.vmodel.perCycleBudget.selected = this.selected2
    this.filterModel.perCycleBudget.option = this.option2
    this.vmodel.heroAICampaignId.selected = this.selected
    this.filterModel.heroAICampaignId.option = this.option
    this.vmodel.googleAccountID.selected = this.selected
    this.filterModel.googleAccountID.option = this.option
    this.vmodel.facebookAccountID.selected = this.selected
    this.filterModel.facebookAccountID.option = this.option
    this.vmodel.pricingModel.selected = this.selected
    this.filterModel.pricingModel.option = this.option
    this.vmodel.bonus.selected = this.selected
    this.filterModel.bonus.option = this.option
    this.vmodel.landingPageType.selected = this.selected
    this.filterModel.landingPageType.option = this.option
    this.vmodel.uniquedSellingPoint.selected = this.selected
    this.filterModel.uniquedSellingPoint.option = this.option
    this.vmodel.servicesFocus.selected = this.selected
    this.filterModel.servicesFocus.option = this.option
    this.vmodel.keywordsIdeas.selected = this.selected
    this.filterModel.keywordsIdeas.option = this.option
    this.vmodel.DIDPhone.selected = this.selected
    this.filterModel.DIDPhone.option = this.option
    this.vmodel.remark.selected = this.selected
    this.filterModel.remark.option = this.option
    this.vmodel.industry.selected = this.selected
    this.filterModel.industry.option = this.option
    this.vmodel.mediaPlan.selected = this.selected
    this.filterModel.mediaPlan.option = this.option
    this.vmodel.contractBudget.selected = this.selected2
    this.filterModel.contractBudget.option = this.option2
    this.vmodel.objective.selected = this.selected
    this.filterModel.objective.option = this.option
    this.vmodel.country.selected = this.selected
    this.filterModel.country.option = this.option
    this.vmodel.lineNotifications.selected = this.selected
    this.filterModel.lineNotifications.option = this.option
    this.vmodel.lineNotificationLanguage.selected = this.selected
    this.filterModel.lineNotificationLanguage.option = this.option
    this.vmodel.artworkPerMonth.selected = this.selected
    this.filterModel.artworkPerMonth.option = this.option
    this.vmodel.howManyPieces.selected = this.selected
    this.filterModel.howManyPieces.option = this.option
    this.vmodel.ANAWorkType.selected = this.selected
    this.filterModel.ANAWorkType.option = this.option
    this.vmodel.googleDriveLink.selected = this.selected
    this.filterModel.googleDriveLink.option = this.option
    this.vmodel.additionalRequirements.selected = this.selected
    this.filterModel.additionalRequirements.option = this.option
    this.vmodel.globalPage.selected = this.selected
    this.filterModel.globalPage.option = this.option
    this.vmodel.existingBot.selected = this.selected
    this.filterModel.existingBot.option = this.option
    this.vmodel.contentFromAM.selected = this.selected
    this.filterModel.contentFromAM.option = this.option
    this.vmodel.requiredFields.selected = this.selected
    this.filterModel.requiredFields.option = this.option
    this.vmodel.chatbotLaunchDate.startDate = this.startDate
    this.vmodel.chatbotLaunchDate.endDate = this.endDate
    this.vmodel.flowType.selected = this.selected
    this.filterModel.flowType.option = this.option
    this.vmodel.leadDataRequirements.selected = this.selected
    this.filterModel.leadDataRequirements.option = this.option
    this.vmodel.productServiceDoc.selected = this.selected
    this.filterModel.productServiceDoc.option = this.option
    this.vmodel.anyFAQ.selected = this.selected
    this.filterModel.anyFAQ.option = this.option
    this.vmodel.anypromotion.selected = this.selected
    this.filterModel.anypromotion.option = this.option
    this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.campaigns.showLoading = true

      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      // หาค่า Opportunity Remaining
      try {
        const body = {
          objectId: this.$route.params.id,
          objectType: 'Opportunity',
        }

        const response = await axios.post('wallet-family/get-balance/', body, axiosConfig)

        this.opportunityRemaining = response.data.balance
      } catch (error) {
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }

        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const qparam = {
          opportunity_id: this.$route.params.id, perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection,
        }

        const creatorId = this.hasMyCampaignAccess && !this.hasAllCampaignAccess ? this.$store.getters['heroAiAuthentications/loginData']?.id : ''

        const response = await AxiosService.post(
          Constant.apiURL.campaign.list,
          qparam,
          { ...creatorId && { creator_id: creatorId } },
        )
        if (response.status === true) {
          this.tableConfig.currentPage = Number(response.data.current_page)
          this.tableConfig.perPage = Number(response.data.per_page)
          this.tableConfig.totalRows = Number(response.data.total)
          this.items = OpportunityCampaignFactory.createFromJsonArray(response.data.data)
          console.dir(this.items)
        } else {
          this.tableConfig.currentPage = 1
          this.tableConfig.totalRows = 0
          this.items = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.items = []
        this.tableConfig.totalRows = 0
      } finally {
        this.$refs.campaigns.showLoading = false
      }
    },

    columnDate(data) {
      if (data) {
        return moment(data).tz('Asia/Bangkok').format('MMMM DD, YYYY')
      }

      return null
    },
    setOpportunityId() {
      store.commit('heroAiCampaign/setOpportunityId', this.$route.params.id)
      this.$router.push({ name: 'campaign-create' })
    },
  },
}
</script>

<style scoped>
.span {
  width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.span:hover {
  white-space: normal;
}
.new-campaign{
  padding: 12px;
}
.b-table-sticky-header {
  max-height: calc(100vh - 350px);
}
</style>
