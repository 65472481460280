<template>
  <div>
    <b-button
      v-if="$can('Create Payment Cycle', 'Opportunities > Payment Calendar')"
      v-b-modal="'payment-create-modal'"
      variant="primary"
      class="ml-0 mr-1 h-25 mb-0"
    >
      Create Payment Cycle
    </b-button>
    <CreatePayment />
    <b-button
      v-if="$can('Renew', 'Opportunities > Payment Calendar')"
      variant="primary"
      class="ml-0 mr-1 h-25 mb-0"
      @click="renewCalender()"
    >
      Renew
    </b-button>
  </div>
</template>
<script>
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import CreatePayment from './CreatePayment.vue'

export default {
  components: {
    CreatePayment,
  },
  methods: {
    doLoadData() {
      return this.$root.$refs.PaymentTable.doLoadData()
    },

    async renewCalender() {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Are you sure you want to renew?' })
      if (alertResult.value) {
        try {
          const response = await AxiosService.get(Constant.apiURL.renewPaymentCalenders.replace('{0}', this.$route.params.id))

          if (response.status === true) {
            this.doLoadData()
            await this.$swal({ ...SweetAlert.success, text: response.message })
          } else {
            await this.$swal({ ...SweetAlert.error, text: response.message })
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
          if (error.response.status === 404) {
            await this.$router.push({ name: 'page-not-found' })
          }
        }
      }
    },
  },
}
</script>
