<template>
  <b-modal
    id="payment-edit-modal"
    ref="payment-edit-modal"
    :title="`Edit Payment Calendar for cycle ${cyclePayload.cycle_number}`"
    centered
    hide-footer
    no-close-on-backdrop
  >
    <b-overlay no-wrap :show="showOverlay" />
    <validation-observer ref="observer" v-slot="{ invalid }">
      <b-form @submit.stop.prevent>
        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Placement Date"
              rules="required"
            >
              <HeroInputDate
                id="placement_date"
                v-model="cyclePayload.placement_date"
                label="Placement Date"
                :required="true"
                column="4"
                :add-calendar="true"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="Payment Date"
              rules="required"
            >
              <HeroInputDate
                id="payment_date"
                v-model="cyclePayload.payment_date"
                label="Payment Date"
                :required="true"
                column="4"
                :add-calendar="true"
                :readonly="true"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- Expected Payment Date -->
          <b-col cols="12">
            <HeroInputDate
              id="expected_payment_date"
              v-model="cyclePayload.expected_payment_date"
              label="Expected Payment Date"
              column="4"
              :add-calendar="true"
              :readonly="true"
            />
          </b-col>

          <!-- Accounting Remark -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="accounting remark"
              rules=""
            >
              <HeroInputText
                id="name"
                v-model="cyclePayload.accounting_remark"
                label="Accounting Remark"
                column="4"
                :required="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- Collection Remark -->
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="collection remark"
              rules=""
            >
              <HeroInputText
                id="name"
                v-model="cyclePayload.collection_remark"
                label="Collection Remark"
                column="4"
                :required="false"
                :state="errors.length > 0 ? false : null "
                :invalid-feedback="errors[0]"
              />
            </validation-provider>
          </b-col>

          <!-- Invoice Remark -->
          <template v-if="cyclePayload.invoices">
            <template v-for="(invoice, index) in cyclePayload.invoices">
              <b-col :key="index" cols="12">
                <validation-provider
                  #default="{ errors }"
                  :name="`invoice remark (${invoice.id})`"
                  rules=""
                >
                  <HeroInputText
                    :id="`invoice-remark-${index}`"
                    v-model="invoice.invoice_remark"
                    label="Invoice Remark"
                    :placeholder="`${invoice.invoice_number}`"
                    column="4"
                    :required="false"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>
            </template>
          </template>
        </b-row>
        <hr>
        <b-row>
          <b-col cols="6" offset-sm="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="primary"
              :disabled="invalid"
              @click="updatePaymentCalendar"
            >
              Save
            </HeroButtonAction>
          </b-col>
          <b-col cols="6" sm="3">
            <HeroButtonAction
              type="button"
              variant="outline-secondary"
              @click="$bvModal.hide('payment-edit-modal')"
            >
              Back
            </HeroButtonAction>
          </b-col>
        </b-row></b-form>
    </validation-observer>
  </b-modal>
</template>
<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroInputDate from '@/views/components/form/inputs/HeroInputDate.vue'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'

export default {
  components: {
    HeroInputText,
    HeroInputDate,
    HeroButtonAction,
  },
  props: {
    cyclePayload: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showOverlay: false,
    }
  },
  methods: {
    async updatePaymentCalendar() {
      const validationResult = await this.$refs.observer.validate()

      if (validationResult) {
        try {
          const payload = {
            opportunity_id: this.cyclePayload.opportunity_id,
            placement_date: this.cyclePayload.placement_date,
            payment_date: this.cyclePayload.payment_date,
            expected_payment_date: this.cyclePayload.expected_payment_date,
            accounting_remark: this.cyclePayload.accounting_remark,
            collection_remark: this.cyclePayload.collection_remark,
            invoice_remark: [],
          }

          const invoiceRemark = []

          this.cyclePayload.invoices.forEach(invoice => {
            invoiceRemark.push({
              invoice_id: invoice.id,
              remark: invoice.invoice_remark,
            })
          })

          payload.invoice_remark = invoiceRemark

          const response = await AxiosService.patch(`${Constant.apiURL.paymentCalendars}/${this.cyclePayload.id}`, null, payload)

          if (response.status === true) {
            await this.$swal({ ...SweetAlert.success, html: response.message })
            this.$bvModal.hide('payment-edit-modal')
            this.$store.state.heroAiCampaign.paymentCalendarState = !this.$store.state.heroAiCampaign.paymentCalendarState
            this.resetModal()
          }
        } catch (error) {
          await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

          if (error.response.status === 401) {
            await this.$router.push({ name: 'heroai-logout' })
          }
          if (error.response.status === 404) {
            await this.$router.push({ name: 'page-not-found' })
          }
        }
      }
    },
    async resetModal() {
      this.cyclePayload = {
        id: '',
        opportunity_id: '',
        placement_date: '',
        payment_date: '',
        expected_payment_date: '',
        accounting_remark: '',
        collection_remark: '',
        invoice_remark: '',
        invoice_id: '',
      }
      await this.$refs.observer.reset()
    },
  },
}
</script>
