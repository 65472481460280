var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"payment-edit-modal",attrs:{"id":"payment-edit-modal","title":("Edit Payment Calendar for cycle " + (_vm.cyclePayload.cycle_number)),"centered":"","hide-footer":"","no-close-on-backdrop":""}},[_c('b-overlay',{attrs:{"no-wrap":"","show":_vm.showOverlay}}),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Placement Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"placement_date","label":"Placement Date","required":true,"column":"4","add-calendar":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.cyclePayload.placement_date),callback:function ($$v) {_vm.$set(_vm.cyclePayload, "placement_date", $$v)},expression:"cyclePayload.placement_date"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Payment Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"payment_date","label":"Payment Date","required":true,"column":"4","add-calendar":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.cyclePayload.payment_date),callback:function ($$v) {_vm.$set(_vm.cyclePayload, "payment_date", $$v)},expression:"cyclePayload.payment_date"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('HeroInputDate',{attrs:{"id":"expected_payment_date","label":"Expected Payment Date","column":"4","add-calendar":true,"readonly":true},model:{value:(_vm.cyclePayload.expected_payment_date),callback:function ($$v) {_vm.$set(_vm.cyclePayload, "expected_payment_date", $$v)},expression:"cyclePayload.expected_payment_date"}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"accounting remark","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"name","label":"Accounting Remark","column":"4","required":false,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.cyclePayload.accounting_remark),callback:function ($$v) {_vm.$set(_vm.cyclePayload, "accounting_remark", $$v)},expression:"cyclePayload.accounting_remark"}})]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"collection remark","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"name","label":"Collection Remark","column":"4","required":false,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.cyclePayload.collection_remark),callback:function ($$v) {_vm.$set(_vm.cyclePayload, "collection_remark", $$v)},expression:"cyclePayload.collection_remark"}})]}}],null,true)})],1),(_vm.cyclePayload.invoices)?[_vm._l((_vm.cyclePayload.invoices),function(invoice,index){return [_c('b-col',{key:index,attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":("invoice remark (" + (invoice.id) + ")"),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":("invoice-remark-" + index),"label":"Invoice Remark","placeholder":("" + (invoice.invoice_number)),"column":"4","required":false,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(invoice.invoice_remark),callback:function ($$v) {_vm.$set(invoice, "invoice_remark", $$v)},expression:"invoice.invoice_remark"}})]}}],null,true)})],1)]})]:_vm._e()],2),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"cols":"6","offset-sm":"6","sm":"3"}},[_c('HeroButtonAction',{attrs:{"type":"button","variant":"primary","disabled":invalid},on:{"click":_vm.updatePaymentCalendar}},[_vm._v(" Save ")])],1),_c('b-col',{attrs:{"cols":"6","sm":"3"}},[_c('HeroButtonAction',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){return _vm.$bvModal.hide('payment-edit-modal')}}},[_vm._v(" Back ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }