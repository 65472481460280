export default class PaymentCalendarHeader {
  constructor(item = {}) {
    return {
      opportunity_id: item.opportunity_id || '',
      opportunity_name: item.opportunity_name || '',
      customer_id: item.customer_id || '',
      customer_name: item.customer_name || '',
      payment_term: item.payment_terms || '',
      status: item.status || '',
      stage: item.stage || '',
      duration: item.duration || '0',
      type: item.type || '',
      slaesorder: item.sales_order || '',
      quote_no: item.quote_number || '',
      invoice_required: (item.invoice_required && item.invoice_required.length > 0) ? item.invoice_required.join(', ') : '',
    }
  }
}
