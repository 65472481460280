<template>
  <b-card no-body>
    <b-tabs card pills>
      <b-tabs v-model="tabIndex" align="left" style="margin-top: -50px">
        <b-tab
          v-if="opportunity"
          title="Details"
        >
          <b-row class="m-1">
            <b-col offset="8" cols="2">
              <HeroButtonAction
                type="button"
                variant="primary"
                :disabled="disableWalletButton"
                @click="addToWalletFamily"
              >
                Add To Wallet Family
              </HeroButtonAction>
            </b-col>
            <b-col cols="2">
              <HeroButtonAction
                type="button"
                variant="primary"
                @click="collapseBtn"
              >
                {{ buttonText }}
              </HeroButtonAction>
            </b-col>
          </b-row>

          <app-collapse accordion :type="collapseType">
            <Incharge :incharge="opportunity.inCharge" :is-visible="isVisible || opportunityState.Incharge" @updatevisible="setAccordianState('Incharge')" />
            <Stage :stage="opportunity.stage" :is-visible="isVisible || opportunityState.Stage" @updatevisible="setAccordianState('Stage')" />
            <Information v-if="!isLoading" :information="opportunity.information" :is-visible="isVisible || opportunityState.Information" @updatevisible="setAccordianState('Information')" />
            <DealTerms :deal-terms="opportunity.deal_terms" :is-visible="isVisible || opportunityState.DealTerms" @updatevisible="setAccordianState('DealTerms')" />
            <Validation v-if="opportunity.validations" :validations="opportunity.validations" :is-visible="isVisible || opportunityState.Validation" @updatevisible="setAccordianState('Validation')" />
            <ClientBilling :client_billing="opportunity.client_billing" :is-visible="isVisible || opportunityState.ClientBilling" @updatevisible="setAccordianState('ClientBilling')" />
            <CampaignDetail :campaign="opportunity.campaign" :is-visible="isVisible || opportunityState.CampaignDetail" @updatevisible="setAccordianState('CampaignDetail')" />
            <AccountManagementInfo :account_management_info="opportunity.account_management_info" :is-visible="isVisible || opportunityState.AccountManagementInfo" @updatevisible="setAccordianState('AccountManagementInfo')" />
            <SystemInformation :system_information="opportunity.system_information" :is-visible="isVisible || opportunityState.SystemInformation" @updatevisible="setAccordianState('SystemInformation')" />
            <SalesOrder :is-visible="isVisible || opportunityState.SalesOrder" @updatevisible="setAccordianState('SalesOrder')" />
            <SalesHistory :is-visible="isVisible || opportunityState.SalesHistory" @updatevisible="setAccordianState('SalesHistory')" />
            <Notes :is-visible="isVisible || opportunityState.Notes" @updatevisible="setAccordianState('Notes')" />
            <Files :is-visible="isVisible || opportunityState.Files" @updatevisible="setAccordianState('Files')" />
            <Quotes :is-visible="isVisible || opportunityState.Quotes" @updatevisible="setAccordianState('Quotes')" />
            <InvoicePayments :is-visible="isVisible || opportunityState.InvoicePayments" @updatevisible="setAccordianState('InvoicePayments')" />
            <Products :is-visible="isVisible || opportunityState.Products" @updatevisible="setAccordianState('Products')" />
          </app-collapse>
        </b-tab>
        <b-tab
          v-if="$can('Access', 'Opportunities > Opportunity Product')"
          title="Opportunity Product"
        >
          <OpportunityProduct />
        </b-tab>
        <b-tab
          v-if="hasCampaignAccess"
          title="Campaign"
        >
          <Campaign v-if="!isLoading" :opportunity-type="opportunity.information.type" />
        </b-tab>
        <b-tab
          v-if="$can('Access', 'Opportunities > Payment Calendar')"
          title="Payment Calendar"
        >
          <b-alert
            class="mx-1"
            variant="info"
            :show="isShowWalletFamilyAlert && !isWalletFamilyLoading"
          >
            <div class="alert-body">
              This opportunity is not part of wallet family yet. It cannot generate payment calendar until have some transaction.
            </div>
          </b-alert>
          <PaymentCalendarDetails />
        </b-tab>
        <b-tab
          v-if="$can('Access', 'Wallets')"
          title="Wallets"
          @click="$router.push(
            {
              name: 'wallets-list',
              query: {
                walletType: 'Opportunity',
                objectId: $route.params.id,
                accountId: opportunity.information.account_id,
              },
            },
          )"
        >
          <div />
        </b-tab>
      </b-tabs>
    </b-tabs>
  </b-card>
</template>
<script>
import store from '@/store'
import axios from '@axios'
import AxiosConfig from '@/services/AxiosConfigs'
import ErrorService from '@/services/ErrorService'
import SweetAlert from '@/services/SweetAlert'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import {
  BTab, BTabs, BCard, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import Incharge from '@/views/heroai/opportunities/view/components/Incharge.vue'
import Stage from '@/views/heroai/opportunities/view/components/Stage.vue'
import Information from '@/views/heroai/opportunities/view/components/Information.vue'
import DealTerms from '@/views/heroai/opportunities/view/components/DealTerms.vue'
import Validation from '@/views/heroai/opportunities/view/components/Validation.vue'
import ClientBilling from '@/views/heroai/opportunities/view/components/ClientBilling.vue'
import CampaignDetail from '@/views/heroai/opportunities/view/components/Campaign.vue'
import AccountManagementInfo from '@/views/heroai/opportunities/view/components/AccountManagementInfo.vue'
import SystemInformation from '@/views/heroai/opportunities/view/components/SystemInformation.vue'
import SalesOrder from '@/views/heroai/opportunities/view/components/SalesOrder.vue'
import SalesHistory from '@/views/heroai/opportunities/view/components/SalesHistory.vue'
import Products from '@/views/heroai/opportunities/view/components/Products.vue'
import OpportunityProduct from '@/views/heroai/opportunities/view/product/view.vue'
import PaymentCalendarDetails from '@/views/heroai/opportunities/view/PaymentCalendar.vue'
import Campaign from '@/views/heroai/opportunities/view/Campaign.vue'
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import Opportunity from '@/models/Opportunity/index'
import OpportunityStateModal from '@/models/Opportunity/OpportunityState'
import HeroButtonAction from '@/views/components/form/buttons/HeroButtonAction.vue'
import Notes from './view/components/Notes.vue'
import Files from './view/components/Files.vue'
import InvoicePayments from './view/components/InvoicePayments.vue'
import Quotes from './view/components/Quotes.vue'

export default {
  components: {
    HeroButtonAction,
    AppCollapse,
    BTab,
    BTabs,
    BCard,
    Incharge,
    Stage,
    Information,
    DealTerms,
    Validation,
    SalesHistory,
    ClientBilling,
    CampaignDetail,
    AccountManagementInfo,
    SystemInformation,
    SalesOrder,
    Products,
    OpportunityProduct,
    PaymentCalendarDetails,
    Campaign,
    Notes,
    Files,
    InvoicePayments,
    Quotes,
    BRow,
    BCol,
    BAlert,
  },
  data() {
    return {
      opportunityState: new OpportunityStateModal({}),
      collapseType: 'border',
      opportunity: {},
      isVisible: false,
      isLoading: true,
      tabIndex: 0,
      disableWalletButton: true,
      isWalletFamilyLoading: true,
    }
  },
  computed: {
    opportunitystate() {
      return store.getters['heroAiCampaign/getOpportunityState']
    },

    buttonText() {
      return this.checkExpandState()
    },

    isShowWalletFamilyAlert() {
      return !this.disableWalletButton
    },

    hasCampaignAccess() {
      return this.$can('Access - My Campaign', 'Campaigns') || this.$can('Access - All Campaign', 'Campaigns')
    },
  },
  mounted() {
    this.isLoading = false
    this.getWalletFamily()
    this.getOpportunityDetail()
    this.opportunityState = store.getters['heroAiCampaign/getOpportunityState']
    if (!this.opportunityState) {
      this.opportunityState = new OpportunityStateModal({})
      store.commit('heroAiCampaign/setOpportunityState', new OpportunityStateModal({}))
    }
    this.checkAccordianState()
  },
  methods: {
    async getOpportunityDetail() {
      this.isLoading = true
      try {
        const qparam = {
          opportunity_id: this.$route.params.id,
        }
        const response = await AxiosService.get(Constant.apiURL.salesforceOpportunityDetail, qparam)
        this.opportunity = new Opportunity(response.data)
        console.log('this.opportunity')

        console.log(this.opportunity)
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
      }
      this.isLoading = false
    },
    collapseBtn() {
      let ExpandAll = false
      if (this.buttonText === 'Expand All') {
        ExpandAll = true
      }
      const OpportunityState = this.opportunityState
      Object.keys(this.opportunityState).forEach(x => {
        OpportunityState[x] = ExpandAll
      })
      this.opportunityState = OpportunityState
      store.commit('heroAiCampaign/setOpportunityState', this.opportunityState)
      this.isVisible = ExpandAll
    },
    checkExpandState() {
      let AllCollapsed = true
      Object.keys(this.opportunityState).forEach(x => {
        if (this.opportunityState[x]) {
          AllCollapsed = false
        }
      })
      return AllCollapsed ? 'Expand All' : 'Collapse All'
    },
    checkAccordianState() {
      let AllCollapsed = true
      Object.keys(this.opportunityState).forEach(x => {
        if (this.opportunityState[x]) {
          AllCollapsed = false
        }
      })
      if (AllCollapsed && this.isVisible) {
        this.isVisible = false
      }
    },
    setAccordianState(propertyName) {
      const PreviousState = this.opportunityState[propertyName]
      this.opportunityState[propertyName] = !PreviousState
      store.commit('heroAiCampaign/setOpportunityState', this.opportunityState)
      this.checkAccordianState()
    },
    async addToWalletFamily() {
      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)
      const body = {}

      try {
        const url = `wallet-family/add-to-family/${this.$route.params.id}`
        const response = await axios.post(url, body, axiosConfig)

        this.disableWalletButton = true

        await this.$swal({ ...SweetAlert.success, text: response.data.message })
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      }
    },
    async getWalletFamily() {
      const token = store.getters['heroAiAuthentications/sanctumToken']
      const axiosConfig = AxiosConfig.getJsonWithToken(token)

      try {
        const url = `wallet-family/get-wallet-family/${this.$route.params.id}`
        const response = await axios.get(url, axiosConfig)

        this.disableWalletButton = response.data.disableButton
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
      } finally {
        this.isWalletFamilyLoading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
