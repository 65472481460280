<template>
  <div>
    <Header :opportunity="calendarDetail" />
    <b-table-simple responsive bordered class="text-center" style="font-weight: normal; font-size: 12px; white-space:nowrap;">
      <b-thead>
        <b-tr>
          <b-th rowspan="2" style="vertical-align: middle;">
            Action
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Cycle
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Cycle Start Date
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Cycle End Date
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Placement Date
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Payment Date
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Expected Payment Date
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Invoice Number
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Total Amount
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Total Amount (Incl. VAT)
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Invoice Due Date
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Invoice Remark
          </b-th>
          <b-th rowspan="1" colspan="4" style="vertical-align: middle;">
            Receivables
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Accounting Remark
          </b-th>
          <b-th rowspan="2" style="vertical-align: middle;">
            Collection Remark
          </b-th>
        </b-tr>
        <b-tr>
          <b-th>Document Number</b-th>
          <b-th>Amount</b-th>
          <b-th>Description</b-th>
          <b-th>Created At</b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <template v-for="(cycle, cycleIndex) in calendarList">
          <!-- กรณี ถ้ามีข้อมูล Invoices -->
          <template v-if="cycle.invoices && cycle.invoices.length > 0">
            <template v-for="(invoice, invoiceIndex) in cycle.invoices">
              <!-- กรณี ถ้ามีข้อมูล Ledgers -->
              <template v-if="invoice.ledgers && invoice.ledgers.length > 0">
                <template v-for="(ledger, ledgerIndex) in invoice.ledgers">
                  <!-- แถวแรกของแต่ละ Cycle -->
                  <template v-if="invoiceIndex === 0 && ledgerIndex === 0">
                    <b-tr :key="`row-${cycleIndex}-${invoiceIndex}-${ledgerIndex}`">
                      <b-td :rowspan="cycle.ledgers_count">
                        <feather-icon class="cursor-pointer" icon="Edit2Icon" @click="openEditCycle(cycle)" />
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count">
                        {{ cycle.cycle_number || '-' }}
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count">
                        {{ formatUsaDate(cycle.cycle_start_date) }}
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count">
                        {{ formatUsaDate(cycle.cycle_end_date) }}
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count" :class="cycle.placement_date_touched ? 'date-touched' : ''">
                        {{ formatUsaDate(cycle.placement_date) }}
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count" :class="cycle.payment_date_touched ? 'date-touched' : ''">
                        {{ formatUsaDate(cycle.payment_date) }}
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count">
                        {{ formatUsaDate(cycle.expected_payment_date) }}
                      </b-td>

                      <b-td :rowspan="invoice.ledgers_count">
                        {{ invoice.invoice_number || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers_count">
                        {{ formatMoney(invoice.total_amount) || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers_count">
                        {{ formatMoney(invoice.total_amount_including_vat) || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers_count">
                        {{ formatUsaDate(invoice.due_date) || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers_count">
                        {{ invoice.invoice_remark || '-' }}
                      </b-td>

                      <b-td :rowspan="1">
                        {{ ledger.document_number }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ formatMoney(ledger.amount) }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ ledger.description }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ formatUsaDate(ledger.sf_create_date) }}
                      </b-td>

                      <b-td :rowspan="cycle.ledgers_count">
                        {{ cycle.accounting_remark || '-' }}
                      </b-td>
                      <b-td :rowspan="cycle.ledgers_count">
                        {{ cycle.collection_remark || '-' }}
                      </b-td>
                    </b-tr>
                  </template>

                  <template v-if="invoiceIndex > 0 && ledgerIndex === 0 && invoice.ledgers.length > 0">
                    <b-tr :key="`row-${cycleIndex}-${invoiceIndex}-${ledgerIndex}`">
                      <b-td :rowspan="invoice.ledgers.length">
                        {{ invoice.invoice_number || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers.length">
                        {{ formatMoney(invoice.total_amount) || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers.length">
                        {{ formatMoney(invoice.total_amount_including_vat) || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers.length">
                        {{ formatUsaDate(invoice.due_date) || '-' }}
                      </b-td>
                      <b-td :rowspan="invoice.ledgers.length">
                        {{ invoice.invoice_remark || '-' }}
                      </b-td>

                      <b-td :rowspan="1">
                        {{ ledger.document_number }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ formatMoney(ledger.amount) }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ ledger.description }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ formatUsaDate(ledger.sf_create_date) }}
                      </b-td>
                    </b-tr>
                  </template>

                  <!-- แถวที่สองขึ้นไปของแต่ละ Ledger -->
                  <template v-if="ledgerIndex > 0">
                    <b-tr :key="`row-${cycleIndex}-${invoiceIndex}-${ledgerIndex}`">
                      <b-td :rowspan="1">
                        {{ ledger.document_number }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ formatMoney(ledger.amount) }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ ledger.description }}
                      </b-td>
                      <b-td :rowspan="1">
                        {{ formatUsaDate(ledger.sf_create_date) }}
                      </b-td>
                    </b-tr>
                  </template>
                </template>
              </template>

              <!-- กรณี ถ้าไม่มีข้อมูล Ledgers -->
              <template v-else>
                <!-- แถวแรกของแต่ละ Cycle -->
                <template v-if="invoiceIndex === 0">
                  <b-tr :key="`row-${cycleIndex}-${invoiceIndex}-1`">
                    <b-td :rowspan="cycle.ledgers_count">
                      <feather-icon class="cursor-pointer" icon="Edit2Icon" @click="openEditCycle(cycle)" />
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count">
                      {{ cycle.cycle_number || '-' }}
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count">
                      {{ formatUsaDate(cycle.cycle_start_date) }}
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count">
                      {{ formatUsaDate(cycle.cycle_end_date) }}
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count" :class="cycle.placement_date_touched ? 'date-touched' : ''">
                      {{ formatUsaDate(cycle.placement_date) }}
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count" :class="cycle.payment_date_touched ? 'date-touched' : ''">
                      {{ formatUsaDate(cycle.payment_date) }}
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count">
                      {{ formatUsaDate(cycle.expected_payment_date) }}
                    </b-td>

                    <b-td :rowspan="1">
                      {{ invoice.invoice_number || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ formatMoney(invoice.total_amount) || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ formatMoney(invoice.total_amount_including_vat) || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ formatUsaDate(invoice.due_date) || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ invoice.invoice_remark || '-' }}
                    </b-td>

                    <b-td :rowspan="1">
                      -
                    </b-td>
                    <b-td :rowspan="1">
                      -
                    </b-td>
                    <b-td :rowspan="1">
                      -
                    </b-td>
                    <b-td :rowspan="1">
                      -
                    </b-td>

                    <b-td :rowspan="cycle.ledgers_count">
                      {{ cycle.accounting_remark || '-' }}
                    </b-td>
                    <b-td :rowspan="cycle.ledgers_count">
                      {{ cycle.collection_remark || '-' }}
                    </b-td>
                  </b-tr>
                </template>

                <template v-if="invoiceIndex > 0">
                  <b-tr :key="`row-${cycleIndex}-${invoiceIndex}-1`">
                    <b-td :rowspan="1">
                      {{ invoice.invoice_number || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ formatMoney(invoice.total_amount) || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ formatMoney(invoice.total_amount_including_vat) || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ formatUsaDate(invoice.due_date) || '-' }}
                    </b-td>
                    <b-td :rowspan="1">
                      {{ invoice.invoice_remark || '-' }}
                    </b-td>

                    <b-td :rowspan="1">
                      -
                    </b-td>
                    <b-td :rowspan="1">
                      -
                    </b-td>
                    <b-td :rowspan="1">
                      -
                    </b-td>
                    <b-td :rowspan="1">
                      -
                    </b-td>
                  </b-tr>
                </template>
              </template>
            </template>
          </template>

          <!-- กรณี ถ้าไม่มีข้อมูล Invoices -->
          <template v-else>
            <b-tr :key="`row-${cycleIndex}-1-1`">
              <b-td :rowspan="1">
                <feather-icon class="cursor-pointer" icon="Edit2Icon" @click="openEditCycle(cycle)" />
              </b-td>
              <b-td :rowspan="1">
                {{ cycle.cycle_number || '-' }}
              </b-td>
              <b-td :rowspan="1">
                {{ formatUsaDate(cycle.cycle_start_date) }}
              </b-td>
              <b-td :rowspan="1">
                {{ formatUsaDate(cycle.cycle_end_date) }}
              </b-td>
              <b-td :rowspan="1" :class="cycle.placement_date_touched ? 'date-touched' : ''">
                {{ formatUsaDate(cycle.placement_date) }}
              </b-td>
              <b-td :rowspan="1" :class="cycle.payment_date_touched ? 'date-touched' : ''">
                {{ formatUsaDate(cycle.payment_date) }}
              </b-td>
              <b-td :rowspan="1">
                {{ formatUsaDate(cycle.expected_payment_date) }}
              </b-td>

              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>

              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>
              <b-td :rowspan="1">
                -
              </b-td>

              <b-td :rowspan="1">
                {{ cycle.accounting_remark || '-' }}
              </b-td>
              <b-td :rowspan="1">
                {{ cycle.collection_remark || '-' }}
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
    <EditPayment :cycle-payload="cyclePayload" />
  </div>
</template>

<script>
import AxiosService from '@/services/AxiosService'
import Constant from '@/utils/Constant'
import SweetAlert from '@/services/SweetAlert'
import ErrorService from '@/services/ErrorService'
import PaymentCalendarHeader from '@/models/Opportunity/PaymentCalendarHeader'
import DataFormatService from '@/services/DataFormatService'
import EditPayment from './EditPayment.vue'
import Header from './Header.vue'

export default {
  components: {
    EditPayment,
    Header,
  },
  data() {
    return {
      calendarDetail: new PaymentCalendarHeader(),
      calendarList: [],
      cyclePayload: {
        id: '',
        opportunity_id: '',
        placement_date: '',
        payment_date: '',
        expected_payment_date: '',
        accounting_remark: '',
        collection_remark: '',
        invoice_remark: '',
        invoice_id: '',
      },
    }
  },
  computed: {
    paymentCalendarState() {
      return this.$store.state.heroAiCampaign.paymentCalendarState
    },
  },
  watch: {
    paymentCalendarState: {
      handler() {
        this.doLoadData()
      },
    },
  },
  created() {
    this.$root.$refs.PaymentTable = this
    this.formatUsaDate = DataFormatService.formatUsaDate
    this.formatDate = DataFormatService.formatDate
    this.formatMoney = DataFormatService.formatMoney
  },
  mounted() {
    this.doLoadData()
  },
  methods: {
    async doLoadData() {
      try {
        const response = await AxiosService.get(Constant.apiURL.opportunityPaymentCalenders.replace('{0}', this.$route.params.id))

        if (response.status === true) {
          this.calendarDetail = new PaymentCalendarHeader(response.data.detail)
          const calendarDataList = response.data.list

          calendarDataList.forEach((element, index) => {
            let ledgersCount = 0

            if (element.invoices && element.invoices.length > 0) {
              calendarDataList.invoices_count += 1

              element.invoices.forEach((invoices, invoiceindex) => {
                const invoiceRemark = element.invoice_remark.filter(x => x.invoice_id === invoices.id).map(x => x.remark)

                if (invoiceRemark && invoiceRemark.length > 0) {
                  calendarDataList[index].invoices[invoiceindex].invoice_remark = invoiceRemark.join(', ')
                } else {
                  calendarDataList[index].invoices[invoiceindex].invoice_remark = ''
                }

                if (invoices.ledgers && invoices.ledgers.length > 0) {
                  ledgersCount += invoices.ledgers.length
                  calendarDataList[index].invoices[invoiceindex].ledgers_count = ledgersCount
                } else {
                  ledgersCount += 1
                  calendarDataList[index].invoices[invoiceindex].ledgers_count = 1
                }
              })
            }

            calendarDataList[index].ledgers_count = ledgersCount || 1
          })

          this.calendarList = response.data.list
          console.dir(this.calendarList)
        } else {
          this.calendarDetail = new PaymentCalendarHeader()
          this.calendarList = []
        }
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }

        if (error.response.status === 404) {
          await this.$router.push({ name: 'page-not-found' })
        }
        this.calendarDetail = []
      }
    },

    openEditCycle(item) {
      const payload = {
        id: item.id,
        cycle_number: item.cycle_number,
        opportunity_id: this.$route.params.id,
        placement_date: this.formatDate(item.placement_date),
        payment_date: this.formatDate(item.payment_date),
        expected_payment_date: this.formatDate(item.expected_payment_date),
        accounting_remark: item.accounting_remark ?? '',
        collection_remark: item.collection_remark ?? '',
        invoices: item.invoices ?? null,
      }

      this.cyclePayload = payload
      this.$bvModal.show('payment-edit-modal')
    },
  },
}
</script>

<style scoped>
.date-touched {
  background-color: #008000;
  color: #FFFFFF;
}
</style>
